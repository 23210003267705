<template>
  <div class="popup">
    <div class="popup-content">
      <h2 :class="dropTitleClasses">
        <span>{{ t('wonItemTitle') }}</span>
      </h2>
      <div class="drop-card">
        <div class="drop-card__top">
          <div
            class="drop-img"
            :class="isCsItem ? 'drop-img--cs' : ''"
            :style="{
              '--rarity': rarity,
              'background': 'linear-gradient(180deg, #0E1030 34.38%, var(--rarity) 100%)',
            }"
          >
            <UiImage :src="image" />
            <LazySvgoDarkSteamIcon v-if="isType3" class="drop-card__steam-icon" />
          </div>
          <div class="drop-descr">
            <SharedText
              class="drop-card__text"
              :font-family="isCsItem ? FontFamily.QUANTICO : FontFamily.RADIANCE"
              :weight="FontWeights.MEDIUM"
              :size="fontSizeDescr"
              :color="rarity"
              :text-shadow="`0 0 16px ${rarity}`"
            >
              {{ name }}
            </SharedText>
            <DropSkinPrice
              class="drop-descr--price"
              :currency="actualCurrency"
              :price-value-right="marketPrice"
              :price-value-left="price"
              :price-name-left="'PUNCH'"
              :price-name-right="'STEAM MARKET'"
              :color-background="Colors.BK_INTEGRATION.PRICE_PUNCH"
              :color-text="Colors.BK_INTEGRATION.BALANCE_GREEN"
              :size="sizeDropSkinPrice"
            />
          </div>
        </div>
        <DropSkinsButtons
          class="drop-buttons"
          :offer="offerData"
          :theme="'purple'"
          :price-time-available="isShowTimer"
          :main-label="priceDescription"
          size="fullWidth"
          :action-gap="actionGap"
          :main-button="{
            buttonProps: styleMainButton,
            onClick: sellItem,
          }"
          :action-buttons="actionButtonsWithListener"
          @on-expire="isShowTimer = false"
        />
      </div>
      <SharedText
        as="div"
        :line-height="LineHeightSizes.LARGE"
        :align="'center'"
        :color="Colors.NEUTRAL.WHITE_50"
        :size="priceSize"
        class="drop-sale__text"
      >
        {{ t('wonItemTakeContent') }}
        <SharedText
          :size="priceSize"
          as="span"
          cursor="pointer"
          :color="Colors.BK_INTEGRATION.BALANCE_GREEN"
          @click="closeAndRedirect"
        >
          {{ t('wonItemTakeInventory') }}.
        </SharedText>
        <br />
        {{ t('wonItemExpire') }}
      </SharedText>
    </div>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useBkCasesStore } from '../../store/bk.cases';
import { Colors } from '~/constants/colors.constants';
import { FontFamily, FontSizes, FontWeights, LineHeightSizes } from '~/types/SharedFont.types';
import { Breakpoints } from '~/constants/media.constants';
import type { IDropSkinButtonProp } from '~/components/DropSkinsButtons/DropSkinsButtons.types';
import {
  styleActionButtonsDesktop,
  styleActionButtonsMobile,
  styleActionButtonsTablet,
  styleMainButtonDesktop,
  styleMainButtonMobile,
  styleMainButtonTablet,
} from '~/features/bk/components/BKIntegrationWonPopup/BKIntegrationWonPopup.data';
import { useDemoStore } from '~/features/bk/store/demo';
import { useBkModalStore } from '~/features/bk/store/bk.modal';
import type { TComponentName } from '~/features/bk/types/components.types';
import { ComponentNames, KEY_INJECT_INVENTORY_SERVICE } from '~/features/bk/constants/index.components';
import { useInject } from '~/composables/useInject';
import type BkInventoryService from '~/features/bk/services/BkInventoryService/BkInventoryService';

const { t } = useI18n();

const store = useBkCasesStore();
const demoStore = useDemoStore();
const viewport = useViewport();
const bkModalStore = useBkModalStore();
const config = useRuntimeConfig();

const quantity = ref(1);
const inventoryService = useInject<BkInventoryService>(KEY_INJECT_INVENTORY_SERVICE);

const dropTitleClasses = computed(() => ({ 'drop-title': true, 'drop-title_one': quantity.value === 1 }));
const redirectOnCase = (componentName?: TComponentName | number) => {
  bkModalStore.handlerClose(componentName as TComponentName);
};

const sellItem = () => {
  if (inventoryService && store.dropsWinner?.length) {
    const drop = store.dropsWinner[0].dropItemData;
    if (!drop) return;
    const item = Object.assign(drop, {
      id: demoStore.isDemo ? store.dropsWinner[0].dropItemData.id : store.dropsWinner[0].inventoryId,
    });
    inventoryService.sellWonItem(item);
    bkModalStore.handlerClose();
  }
};

const closeAndRedirect = () => {
  redirectOnCase(ComponentNames.MY_INVENTORY);
};

const { dropsWinner } = storeToRefs(store);

const image = dropsWinner?.value[0]?.dropItemData?.imageData?.image;

const name = dropsWinner?.value[0]?.dropItemData?.name;

const isCsItem = computed(() => {
  return config.public.tastyProject === 'cs';
});

const isType3 = computed(() => {
  return dropsWinner?.value[0]?.dropItemData?.type === '3';
});

const offerData = dropsWinner?.value[0]?.dropItemData?.offerData;

const { price, marketPrice, currency } = dropsWinner?.value[0]?.dropItemData?.priceData || {};

const actionButtonsWithListener = computed<IDropSkinButtonProp[]>(() => {
  return [
    {
      buttonProps: styleActionButton.value,
      label: useI18n().t('returnToCase'),
      onClick: redirectOnCase,
    },
  ];
});

const isEqualTablet = computed(() => viewport.breakpoint.value === Breakpoints.md);
const isMoreXL = computed(() => viewport.isGreaterOrEquals(Breakpoints.xl));
// Адаптив кнопки назад
const styleActionButton = computed(() => {
  if (isEqualTablet.value) return styleActionButtonsTablet;
  return isMoreXL.value ? styleActionButtonsDesktop : styleActionButtonsMobile;
});
// Адаптив кнопки продать
const styleMainButton = computed(() => {
  if (isEqualTablet.value) return styleMainButtonTablet;
  return isMoreXL.value ? styleMainButtonDesktop : styleMainButtonMobile;
});

// показываем таймер, если каунтдаун больше серверного времени
const isShowTimer = ref(offerData.haveBounty);

const actionGap = computed(() => {
  if (isEqualTablet.value) return '40px';
  return '50px';
});

const fontSizeDescr = computed(() => {
  return viewport.isGreaterOrEquals(Breakpoints.xl) ? FontSizes.LARGE_XL : FontSizes.LARGE;
});

const sizeDropSkinPrice = computed(() => {
  return viewport.isGreaterOrEquals(Breakpoints.xl) ? 'default' : 'small';
});

const priceSize = computed(() => {
  return viewport.isGreaterOrEquals(Breakpoints.xl) ? FontSizes.LARGE : FontSizes.SMALL;
});

const actualCurrency = computed(() => {
  return demoStore.isDemo ? demoStore?.currency : currency;
});

const priceDescription = computed(() => {
  if (isShowTimer.value) return t('inventorySellFor');
  return `${t('inventorySellFor')} ${useFormatCurrency(price || 0, actualCurrency?.value, true)}`;
});

const rarity = computed(() => {
  // @ts-expect-error TODO remove or refactor
  return Colors.RARITY[dropsWinner?.value[0]?.dropItemData?.qualityEnum?.name?.toUpperCase()];
});
</script>
<style scoped lang="scss" src="./BKIntegrationWonPopup.scss"></style>
