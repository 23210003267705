<template>
  <div class="popup">
    <div class="popup-content">
      <UiImage src="/static/not-enough-balance.png" :height="imageHeight" width="100%" />
      <h2 class="title">
        <span>{{ t('errors.balanceError').toUpperCase() }}</span>
      </h2>
      <div class="divider"></div>
      <SharedText
        as="div"
        :line-height="LineHeightSizes.LARGE"
        :align="'center'"
        :color="Colors.NEUTRAL.WHITE"
        :size="descriptionTextSize"
        class="description"
      >
        {{ description }}
      </SharedText>
      <div v-if="inventoryItemsPrice > 0 && getNewBkInventoryLength" class="action-btn-block">
        <SharedButton
          radius="8"
          full-height
          full-width
          :width="GlobalUtils.Converting.toPercents(100)"
          :text-props="btnTextStyle"
          disable-minimal-width
          background-style="green"
          @click="sellAllItems"
        >
          {{ buttonLabel }} {{ GlobalUtils.Prices.toFixedDefault(inventoryItemsPrice) }} {{ currency }}
        </SharedButton>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useBkInventoryStore } from '../../store/bk.inventory';
import { Colors } from '~/constants/colors.constants';
import { FontSizes, LineHeightSizes } from '~/types/SharedFont.types';
import { Currency } from '~/constants/currency.constants';
import { Breakpoints } from '~/constants/media.constants';
import { useDemoStore } from '~/features/bk/store/demo';
import { useBkModalStore } from '~/features/bk/store/bk.modal';
import { KEY_INJECT_INVENTORY_SERVICE } from '~/features/bk/constants/index.components';
import type BkInventoryService from '~/features/bk/services/BkInventoryService/BkInventoryService';
import { useInject } from '~/composables/useInject';

const demoStore = useDemoStore();
const bkModalStore = useBkModalStore();
const inventoryStore = useBkInventoryStore();

const { getNewBkInventoryLength, getSellAllPrice } = storeToRefs(inventoryStore);

const viewport = useViewport();

const { t } = useI18n();

const inventoryService = useInject<BkInventoryService>(KEY_INJECT_INVENTORY_SERVICE);

const inventoryItemsPrice = computed(() => {
  if (demoStore.isDemo) {
    return demoStore.totalInventoryPrice;
  }
  return getSellAllPrice.value;
});

const currency = computed(() => Currency.RUB);

const buttonLabel = computed(() => {
  return viewport.isGreaterOrEquals(Breakpoints.md) ? t('sellAllSkinsFromInventory') : t('sellAllFromInventory');
});

const description = computed(() => {
  if (inventoryItemsPrice.value > 0) {
    return t('lackOfFundsSellContent');
  }
  return t('lackOfFundsGeneralContent');
});

const imageHeight = computed(() => {
  return viewport.isGreaterThan(Breakpoints.md) ? '200px' : '128px';
});
const descriptionTextSize = computed(() => {
  return viewport.isGreaterThan(Breakpoints.md) ? FontSizes.LARGE : FontSizes.SMALL;
});

const btnTextStyle = computed(() => {
  return { color: Colors.NEUTRAL.BLACK, size: btnTextSize.value };
});

const btnTextSize = computed(() => {
  return viewport.isGreaterThan(Breakpoints.md) ? FontSizes.LARGE : FontSizes.MEDIUM;
});

const sellAllItems = () => {
  if (inventoryService) inventoryService.sellAllItems();
  bkModalStore.handlerClose();
};
</script>
<style scoped lang="scss" src="./BKIntegrationNotEnoughBalancePopup.scss"></style>
