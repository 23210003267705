<template>
  <!-- @vue-skip -->
  <SharedModal>
    <div class="popup">
      <div class="popup-content">
        <UiImage src="/static/demo-popup.png" class="demo-popup__image" />
        <div class="demo-popup__text-container">
          <p class="demo-popup__title">{{ t('demoModeRestrictionTitle') }}</p>
          <div class="demo-popup__line" />
          <p class="demo-popup__description">{{ t('demoModeRestrictionContent') }}</p>
        </div>
      </div>
    </div>
  </SharedModal>
</template>
<script setup lang="ts">
const { t } = useI18n();
</script>
<style lang="scss" scoped src="./BKIntegrationDemoPopup.scss"></style>
