<template>
  <CarouselCasesWrapper :available="isAvailable" :is-auth="isAuth">
    <template #carousel>
      <BKCarouselCases
        ref="$carousel"
        :carousel-count="getCounterCarousel"
        :is-auth="isAuth"
        :cases="listDropList"
        :padding-wrapper="xl ? '16px' : '8px'"
        :item-count="listDropList[0]?.length || 0"
        :item-size="xl ? 129.14 : 104"
        :item-end-count="itemEndCount"
        :item-start-count="4"
        :container-height="xl ? 160 : 100"
        :is-button-prepend="false"
        :corner-width="xl ? 400 : 240"
        :carousel-height="xl ? 145 : 85"
        :carousel-width="xl ? 1260 : '100%'"
        :line-width="xl ? 1560 : 960"
        @on-carousel-end="emit('toDropPage')"
        @on-next-item="volumeScroll"
      >
        <template #case="{ getCases }">
          <SharedDropItem
            v-for="caseItem in getCases"
            :key="GlobalUtils.Objects.hashedId(+caseItem.id)"
            class="carousel__items"
            :is-transparent-title="!!(caseItem as TCaseDropItem).weapon"
            :item-price="itemPrice(caseItem)"
            :custom-currency="customCurrency"
            :top-line="{
              color: props?.itemTopPriceOptions?.color || '',
            }"
            :item-name="(caseItem as TCaseDropItem).weapon || caseItem.name"
            :item-type="caseItem.type"
            :item-skin="(caseItem as TCaseDropItem).skin"
            :image="caseItem.imageData?.image || ''"
            disable-animation
            disable-shadow
            preview-only
            :main-color="GlobalUtils.Colors.getColorsRarity(caseItem?.qualityEnum?.name)"
            :quality="{
              qualityFull: caseItem?.qualityFull,
              qualityShort: caseItem?.qualityShort,
            }"
            :style="{
              fontWeight: 'var(--black)',
              color: 'var(--neutral-white-0)',
              fontSize: 'var(--font-size-s)',
            }"
          />
        </template>
      </BKCarouselCases>
    </template>
    <template #buttons>
      <BKCarouselButtonNavigationGroup
        :loading="isPending"
        :prepend="false"
        background-color="var(--main-color-500)"
        :auth="isAuth"
        :selected="isSelected"
        :available="isAvailable"
        @on-sell="handlerSell"
      >
        <template v-if="useHasTypeCase(['multiopen'])" #prepend>
          <BKCarouselMultiplierGroup v-model="multiplierValue" />
        </template>

        <template #available>
          <SharedButtonNew
            :form="btnFormPresetsLeft"
            :color="props?.buttonsColorsPreset?.openFast || defaultOpenCaseFastBtnColorPreset"
            :text-props="md ? openCaseBtnBigTextPreset : openCaseBtnSmallTextPreset"
            :class="{ carouselButton: sm }"
            @click="openCase(true)"
          >
            {{ t('openCaseFast') }}
            <template #append>
              <SharedKitTooltip position="top">
                {{ t('openCaseFastHint') }}
              </SharedKitTooltip>
            </template>
          </SharedButtonNew>
          <SharedButtonNew
            class="carouselButton"
            :form="btnFormPresetsRight"
            :color="props?.buttonsColorsPreset?.open || defaultOpenCaseColorPreset"
            :text-props="md ? openCaseBtnBigTextPreset : openCaseBtnSmallTextPreset"
            @click="openCase(false)"
          >
            {{ t('openCase') }}
            <div :style="props.priceOptions" class="text-indent">
              {{ price }}
            </div>
          </SharedButtonNew>
        </template>

        <template #unavailable="{ defaultPriceOptions, defaultButtons }">
          <SharedButton
            class="round-button"
            theme="primary"
            v-bind="defaultButtons"
            @click="navigateTo(useLocaleRoute()(ROUTING.PAYMENT.MAIN))"
          >
            {{ t('topupBalance') }}
          </SharedButton>
          <SharedButton theme="tertiary" v-bind="defaultButtons" disabled>
            Недостаточно &nbsp;
            <SharedText v-bind="defaultPriceOptions" class="text-indent">
              {{ unAvailablePrice }}
            </SharedText>
          </SharedButton>
          <SharedButton
            class="round-button"
            theme="primary"
            v-bind="defaultButtons"
            @click="navigateTo(useLocaleRoute()(ROUTING.SECRETSHOP.MAINPAGE))"
          >
            Secret Shop
          </SharedButton>
        </template>

        <template #auth-icons>
          <SharedGroupIcons is-show-text source-amplitude="Case" @on-icon="authorization">
            <SharedText
              style="white-space: pre-line"
              as="span"
              :color="Colors.NEUTRAL.WHITE"
              :weight="FontWeights.BOLD"
              :size="fontSizesLogin"
              >{{ t('Auth') }}
            </SharedText>
          </SharedGroupIcons>
        </template>

        <template #description-selection-button>
          <p v-if="maxPriceCaseOkForUser" class="description-unavailable">
            {{ t('caseRedirect') }} {{ maxPriceCaseOkForUser?.label?.toUpperCase() }} за
            {{ maxPriceForUser }}
          </p>
          <p v-else-if="isRedirectForAddBalance" class="description-unavailable">{{ t('topupBalance') }}</p>
          <p v-else class="description-unavailable">{{ t('sellAllSkins') }} {{ priceAllSkins }}</p>
        </template>
      </BKCarouselButtonNavigationGroup>
    </template>
  </CarouselCasesWrapper>
</template>

<script setup lang="ts">
import type { VNodeRef } from 'vue';
import type { ICaseCaruselWidgetEmit, ICaseCaruselWidgetProps } from './BKCaseCarouselWidget.types';
import {
  defaultOpenCaseColorPreset,
  defaultOpenCaseFastBtnColorPreset,
  openCaseBtnBigTextPreset,
  openCaseBtnFormPreset,
  openCaseBtnFormPresetLeft,
  openCaseBtnFormPresetRight,
  openCaseBtnSmallTextPreset,
} from './BKCaseCarouselWidget.data';
import type { Type } from '~/repository/modules/auth';
import { Colors } from '~/constants/colors.constants';
import type { Currency } from '~/constants/currency.constants';
import { CurrencyName } from '~/constants/currency.constants';
import useHasTypeCase from '~/features/cases/composables/useHasTypeCase';
import { FontSizes, FontWeights } from '~/types/SharedFont.types';
import type { TCurrencyType } from '~/types/Shared.types';
import type { IDropItem } from '~/repository/modules/cases/cases.types';
import type { IBkCaseDropItem } from '~/repository/modules/bk/bkCases.types';
import BKCarouselCases from '~/features/bk/components/BKCarouselCases/BKCarouselCases.vue';
import CarouselCasesWrapper from '~/features/cases/components/CarouselCasesWrapper/CarouselCasesWrapper.vue';
import BKCarouselButtonNavigationGroup from '~/features/bk/components/BKCarouselButtonNavigationGroup/BKCarouselButtonNavigationGroup.vue';
import BKCarouselMultiplierGroup from '~/features/bk/components/BKCarouselMultiplierGroup/BKCarouselMultiplierGroup.vue';
import type { TCaseDropItem } from '~/features/cases/types/case.types';
import { Breakpoints } from '~/constants/media.constants';

const { xl, md, sm } = useMedia();

const { t } = useI18n();
const viewport = useViewport();
const emit = defineEmits<ICaseCaruselWidgetEmit>();
const props = defineProps<ICaseCaruselWidgetProps>();

const { isAuth, maxPriceCaseOkForUser, balance, multiPriceData, totalAmountItems, isPending } = toRefs(props);

const $carousel = ref<VNodeRef | null>(null);

const priceAllSkins = computed(() =>
  useFormatCurrency(totalAmountItems?.value || 0, CurrencyName.RUB as TCurrencyType),
);

function calculatePrice(price: string | number) {
  if (!props.itemCase) return '';
  const priceData = props.itemCase.priceData;

  // проверяю на корректность приходящей валюты
  const correctCurrency = Number.isNaN(+priceData?.currency) ? priceData?.currency : CurrencyName.RUB;
  const currency = props?.customCurrency ? props.customCurrency : correctCurrency;
  return useFormatCurrency(price, currency as keyof typeof Currency);
}

const unAvailablePrice = computed<ReturnType<typeof useFormatCurrency>>(() => {
  const difference = Math.abs(+(balance?.value || 0) - multiPriceData?.value);
  const differenceRounded = GlobalUtils.Maths.numberOfDecimalPlaces(difference, 2);
  return calculatePrice(differenceRounded);
});
const price = computed<ReturnType<typeof useFormatCurrency>>(() => {
  return calculatePrice(props.multiPriceData);
});

const maxPriceForUser = computed(() => {
  return calculatePrice(maxPriceCaseOkForUser?.value?.priceData.price || '');
});

const btnFormPresetsLeft = computed(() => {
  return viewport.isGreaterOrEquals(Breakpoints.md) ? openCaseBtnFormPresetLeft : openCaseBtnFormPreset;
});

const btnFormPresetsRight = computed(() => {
  return viewport.isGreaterOrEquals(Breakpoints.md) ? openCaseBtnFormPresetRight : openCaseBtnFormPreset;
});

const isSelected = ref<boolean>(false);
const isQuick = ref<boolean>(false);

const getCounterCarousel = computed(() => props.carouselCount);

// значение множителя x1/x2/x3
const multiplierValue = computed({
  get() {
    return props.multiplier;
  },
  set(value: number) {
    emit('update:multiplier', value);
  },
});

/**
 * Эмитим событие авторизации наверх
 * @param {string} value - название события oauth авторизации
 */
const authorization = (value: Type.TTypesOAuthKey): void => {
  emit('auth', value);
};
const handlerSell = (): void => {
  if (maxPriceCaseOkForUser?.value) {
    emit('handler-sell', maxPriceCaseOkForUser?.value?.name);
  } else if (isRedirectForAddBalance.value) {
    emit('handler-redirect-page-balance');
  } else {
    emit('handler-sell-all');
    // сюда запрос на продажу всех скинов
  }
};

/**
 * Открываем кейс - эмитим событие в контроллер,
 * если это быстрое открытие, то метод анимации мы не передаем, а сразу эмитим наверх - (наверху выполняем запрос на
 * открытие кейса)
 * @param {boolean} isQuickValue - тип анимации быстрая или нет
 */

const openCase = (isQuickValue: boolean) => {
  if (!$carousel.value || !$carousel.value.start) return;
  isQuick.value = isQuickValue;
  emit('open', isQuick.value, $carousel.value.start);
};

const volumeScroll = () => {
  emit('onNextItem', isQuick.value);
};

const fontSizesLogin = computed(() => (xl.value ? FontSizes.MEDIUM : FontSizes.SMALL_XS));

// определяем, нужно ли отправить пользователя за пополнением баланса

const isRedirectForAddBalance = computed(() => {
  if (!balance?.value) return false;
  // @ts-expect-error TODO remove or refactor
  return +balance?.value + +totalAmountItems?.value < multiPriceData.value;
});
// @ts-expect-error TODO remove or refactor
const itemPrice = computed<(caseItem: IBkCaseDropItem | TCaseDropItem) => IPriceData>(() => {
  return (caseItem: IDropItem | IBkCaseDropItem) => {
    return {
      currency: props?.itemTopPriceOptions?.currency
        ? props.itemTopPriceOptions?.currency
        : caseItem.priceData?.currency,
      marketPrice: caseItem.priceData?.marketPrice,
      price: caseItem.priceData?.marketPrice || 0,
    };
  };
});
</script>

<style src="./BKCaseCarouselWidget.scss" scoped lang="scss"></style>

<style scoped>
.text-indent {
  --color-fill: v-bind('props.colorFill');
}
</style>
