<template>
  <div class="popup-wrapper">
    <SharedText :size="textSize" v-bind="titleStyle" class="popup-wrapper__title">{{ t('replaceTitle') }}</SharedText>
    <SharedDropItem
      :top-line="{
        color: Colors.BK_INTEGRATION.TOP_PRICE_PURPLE,
      }"
      :item-name="caseItem?.name"
      :image="caseItem?.imageData.image || ''"
      :item-price="caseItem?.priceData"
      :main-color="GlobalUtils.Colors.getColorsRarity(caseItem?.qualityEnum?.name)"
      :style="style"
      preview-only
      disable-shadow
      size="medium"
    />
    <SharedButton
      style="display: block"
      class="popup-wrapper__button"
      :radius="BorderRadius.XS"
      :color="Colors.BK_INTEGRATION.BALANCE_GREEN"
      :disabled="isPending"
      @click="sellItem"
      ><p>{{ t('inventorySellFor') }}</p>
      <span :class="{ 'popup-wrapper__span': countdown && priceAvailable }">
        {{ currentPrice }}
      </span>
      <SharedTimer
        v-if="countdown && priceAvailable"
        :theme="'purple'"
        :description-date="'colon'"
        :format="'day'"
        :size="'smallest'"
        :date="convertSecondsFromUTC(+countdown)"
        :server-date="convertSecondsFromUTC()"
        @on-expire="priceAvailable = false"
      />
    </SharedButton>
    <SharedText class="popup-wrapper__description" v-bind="styleDescription">{{ t('replaceDescription') }}</SharedText>
    <div class="popup-wrapper__footer">
      <div v-if="!isPending" class="popup-wrapper__list">
        <SharedDropItem
          v-for="item in itemReplacement"
          :key="item.id"
          :price-to-compare="String(caseItem?.priceData?.price)"
          :border-color="Colors.BK_INTEGRATION.BALANCE_GREEN"
          :active-bg-color="bgColor"
          :checkmark-color="Colors.NEUTRAL.BLACK"
          :active-icon-bg-color="Colors.BK_INTEGRATION.BALANCE_GREEN"
          :selected="isPicked(item.id)"
          :top-line="{
            color: Colors.BK_INTEGRATION.TOP_PRICE_PURPLE,
          }"
          :item-name="item.name"
          :image="item.imageData.image"
          :item-price="{
            currency: currentCurrency!,
            price: convertToCurrency(+item?.priceData?.price, exchangeRate),
          }"
          :main-color="GlobalUtils.Colors.getColorsRarity(item?.qualityEnum?.name)"
          :style="style"
          disable-shadow
          size="medium"
          @click="updateChangeList(item)"
        >
        </SharedDropItem>
      </div>
      <div v-else class="popup-wrapper__loader">
        <CaseLoader />
      </div>
      <div style="position: relative">
        <SharedButton
          :radius="BorderRadius.XL4"
          :disabled="!isTradeAvailable || isPending"
          min-width-large="256px"
          :size="size"
          :text-props="{
            weight: FontWeights.BOLD,
            size: fontSize,
          }"
          @click="replaceItem"
          >{{ t('replaceItem') }}
        </SharedButton>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { toRefs } from 'vue';
import { useBkInventoryStore } from '~/features/bk/store/bk.inventory';
import type {
  IChangeItemEmits,
  IChangeitemProps,
} from '~/features/bk/components/BKIntegrationChangeItem/BKIntegrationChangeItem.types';
import { BorderRadius } from '~/constants/common.constants';
import { Colors } from '~/constants/colors.constants';
import SharedTimer from '~/components/SharedTimer/SharedTimer.vue';
import { FontSizes, FontWeights } from '~/types/SharedFont.types';
import CaseLoader from '~/features/cases/components/CaseLoader/CaseLoader.vue';
import {
  descriptionMobileStyle,
  descriptionStyle,
  titleStyle,
} from '~/features/bk/components/IFrameSlider/IFrameSlider.data';
import { Breakpoints } from '~/constants/media.constants';
import type { IBkCaseDropItem } from '~/repository/modules/bk/bkCases.types';

const { t } = useI18n();

const bkInventoryStore = useBkInventoryStore();
const { currentCurrency, exchangeRate } = storeToRefs(bkInventoryStore);

const props = defineProps<IChangeitemProps>();
const emit = defineEmits<IChangeItemEmits>();
const priceAvailable = ref<boolean>(true);
const countdown = props.caseItem?.offerData?.countdown;

const { convertToCurrency } = GlobalUtils.Currency;

const style = computed(() => {
  return {
    'color': 'var(--neutral-white-0)',
    'font-size': 'var(--font-size-s)',
    'font-weight': 'var(--black)',
  };
});

const bgColor = computed(() => {
  const { r, g, b } = GlobalUtils.Colors.hex2Rgb(Colors.BK_INTEGRATION.BALANCE_GREEN, 'object');
  return `${r} ${g} ${b}`;
});

const currentPrice = computed(() => {
  if (!props.caseItem) return;
  return priceAvailable.value && props.caseItem.offerData.countdown
    ? useFormatCurrency(props.caseItem.offerData.price, props.caseItem.offerData.currency)
    : useFormatCurrency(props.caseItem.priceData.price, props.caseItem.priceData.currency);
});

const { itemReplacement, tradeList, caseItem } = toRefs(props);

const isTradeAvailable = computed(() => {
  if (!caseItem.value || !currentReplacementPrice.value || !tradeList.value.length) return false;
  return +caseItem.value.priceData!.price >= currentReplacementPrice.value;
});

const viewport = useViewport();
const isMoreXL = computed(() => viewport.isGreaterOrEquals(Breakpoints.xl));

const size = computed(() => {
  return viewport.isGreaterOrEquals(Breakpoints.md) ? 'large' : 'medium';
});

const textSize = computed(() => {
  return viewport.isGreaterOrEquals(Breakpoints.md) ? FontSizes.LARGE_2XL : FontSizes.LARGE_XL;
});

const styleDescription = computed(() => {
  return isMoreXL.value ? descriptionStyle : descriptionMobileStyle;
});

const fontSize = computed(() => {
  return viewport.isGreaterOrEquals(Breakpoints.md) ? FontSizes.LARGE : FontSizes.MEDIUM;
});

// const casePrice = computed(() => {
//   if (!caseItem.value) return '';
//   const priceData = caseItem.value!.priceData;
//   return useFormatCurrency(priceData.price, priceData.currency as keyof typeof Currency);
// });

function isPicked(id: number | string) {
  const isIn = tradeList.value.find((item) => item.id === id);
  return Boolean(isIn);
}

const currentReplacementPrice = computed<number>(() => {
  return tradeList?.value?.reduce((acc: number, item: IBkCaseDropItem) => {
    acc += Number(convertToCurrency(+item?.priceData?.price, exchangeRate.value));
    return acc;
  }, 0);
});

function updateChangeList(item: IBkCaseDropItem) {
  emit('update-item', item);
}

function replaceItem() {
  emit('replace-item');
}

function sellItem() {
  emit('sell-item');
}
</script>

<style src="./BKIntegrationChangeItem.scss" scoped lang="scss"></style>
