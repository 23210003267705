<template>
  <div :style="styles" class="login-icons">
    <div v-if="isShowText" class="login-icons__text">
      <slot />
    </div>

    <div class="login-icons__list">
      <button
        v-for="icon in mappedIcons"
        :key="icon.componentName + icon"
        :class="[
          'login-icons__icon login-icons__icon--' + (icon.classes || icon.componentName),
          icon.isLong && 'login-icons__icon--long',
          icon.isFixed && 'login-icons__icon--fixed',
        ]"
        :disabled="isDisabled"
        @click="handlerClick(icon.eventName, icon?.callback)"
      >
        <component :is="'LazySvgo' + icon.componentName" :key="icon.componentName" class="login-icon" />
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { CSSProperties } from 'vue';
import { computed, toRefs } from 'vue';
import { newDefaultIcons } from '~/components/SharedGroupIcons/SharedGroupIconsTD.data';
import type {
  ISharedGroupIconsEmit,
  ISharedGroupIconsProps,
} from '~/components/SharedGroupIcons/SharedGroupIcons.types';
import type { Type } from '~/repository/modules/auth';
import { AuthEvents } from '~/repository/amplitude/events/auth';

const emit = defineEmits<ISharedGroupIconsEmit>();
const props = withDefaults(defineProps<ISharedGroupIconsProps>(), {
  icons: () => newDefaultIcons,
  isShowText: false,
  sourceAmplitude: 'Header',
  useFixedIconSizes: false,
  widthText: 45,
});

const { flexDirection, alignItems } = toRefs(props);

const signupAmplitude = useSignupAmplitude();

const mappedIcons = computed(() => {
  const result = props.useFixedIconSizes ? props.icons.map((icon) => ({ ...icon, isFixed: true })) : props.icons;

  return result;
});

const styles = computed<CSSProperties>(() => {
  const properties: CSSProperties = {};
  if (flexDirection?.value) properties.flexDirection = flexDirection.value;
  if (alignItems?.value) properties.alignItems = alignItems.value;
  return properties;
});

const handlerClick = (eventName: Type.TTypesOAuthKey, cb?: () => void) => {
  signupAmplitude.saveType(eventName);
  signupAmplitude.saveCreationData();

  if (!props.dontSendAmplitude && props.buttonsPlace && window) {
    AuthEvents.buttonClicked({
      SignUpType: eventName,
      Url: location.href,
    });
  }
  emit('onIcon', eventName);
  cb && cb();
};
</script>

<style lang="scss" scoped src="./SharedGroupIcons.scss"></style>
