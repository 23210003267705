<template>
  <div v-if="isMobile && !item.isOld" class="item-container" :style="styles">
    <div :class="['item-image', csItemImageClass]">
      <div class="item-image__picture-overlay" />
      <UiImage class="item-image__picture" :src="item.imageData.image" />
      <div v-if="item.type === '3'" class="item-info__steam-icon item-info__steam-icon--mobile">
        <LazySvgoDarkSteamIcon />
      </div>
      <div v-if="isTimer" class="item-container__layout">
        <div class="item-container__layout-bg"></div>
      </div>
      <div v-if="isTimer" class="item-timer">
        <SharedTimer
          :key="countTimer"
          size="smallest"
          description-date="colon"
          format="time"
          :date="timerDate"
          :text-props="{ size: '14px' }"
          @on-expire="handleExpire"
        />
      </div>
    </div>

    <div class="item-info">
      <div class="item-info__price">
        <SharedText :color="Colors.NEUTRAL.WHITE" :size="FontSizes.SMALL_XS" :weight="FontWeights.MEDIUM">
          <BaseCurrency>{{ item.priceData.price || '0' }}</BaseCurrency>
        </SharedText>
      </div>
      <div class="item-info__cs-text">
        <SharedText
          v-if="item.isStatTrak"
          class="item-info__cs-st"
          :class="itemInfoStClass"
          :color="Colors.RARITY.ANCIENT"
          :size="FontSizes.SMALL_XS"
          :weight="FontWeights.BLACK"
        >
          ST
        </SharedText>
        <SharedText
          v-if="isBkCs"
          class="item-info__cs-skin"
          :color="Colors.NEUTRAL.WHITE"
          :size="FontSizes.SMALL_XS"
          :weight="FontWeights.MEDIUM"
          >{{ item.weapon }}
        </SharedText>
        <SharedText
          v-else
          class="item-info__name"
          :color="Colors.NEUTRAL.WHITE"
          :size="FontSizes.SMALL_XS"
          :weight="FontWeights.MEDIUM"
        >
          <span v-if="item.qualityShort">({{ item.qualityShort }})&nbsp;</span> {{ item.name }}
        </SharedText>
        <SharedText
          class="item-info__name"
          :color="Colors.NEUTRAL.WHITE"
          :size="FontSizes.SMALL_XS"
          :weight="FontWeights.MEDIUM"
        >
          <span v-if="item.qualityShort">({{ item.qualityShort }})&nbsp;</span> {{ item.skin }}
        </SharedText>
      </div>
      <div class="item-info__action-buttons">
        <SharedButtonNew
          v-if="showSellItemBtn"
          :form="itemSmallButtonFormPreset"
          :color="sellButtonColorPreset"
          :text-props="sellButtonTextPreset"
          @click="sellItem"
        >
          {{ t('sell') }}
        </SharedButtonNew>
        <SharedButtonNew
          v-if="showTakeItemInfo"
          :state="{ disabled: true }"
          :form="itemSmallButtonFormPreset"
          :color="getButtonColorPreset"
          :text-props="getButtonTextPreset"
          :style="statusBtnStyles"
        >
          {{ t(`${item.takeItemState}`) }}
        </SharedButtonNew>
        <!-- @vue-skip -->
        <SharedButtonNew
          v-if="showTakeItemBtn"
          :form="itemSmallButtonFormPreset"
          :color="getButtonColorPreset"
          :text-props="getButtonTextPreset"
          @click="sendItem"
        >
          {{ t('take') }}
        </SharedButtonNew>
        <SharedButtonNew
          v-if="item.status === statuses.completed"
          :form="itemSmallButtonFormPreset"
          :color="getButtonColorPreset"
          :text-props="getButtonTextPreset"
          @click="getItem"
        >
          {{ t('get') }}
        </SharedButtonNew>
      </div>
    </div>
  </div>
  <div
    v-else
    :class="['item-container__desktop', csDesktopContainerClass]"
    :style="styles"
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <div v-if="isTimer" class="item-container__layout">
      <div class="item-container__layout-bg"></div>
    </div>
    <div class="item-info__price-desktop">
      <SharedText
        v-if="item.isStatTrak"
        class="item-info__cs-st"
        :class="itemInfoStClass"
        :color="Colors.RARITY.ANCIENT"
        :size="viewport.isGreaterThan(Breakpoints.sm) ? FontSizes.SMALL : FontSizes.SMALL_2XS"
        :weight="FontWeights.BLACK"
      >
        ST
      </SharedText>
      <SharedText
        :color="Colors.NEUTRAL.WHITE"
        :size="isMobile ? FontSizes.SMALL_XS : FontSizes.SMALL"
        :weight="isMobile ? FontWeights.BOLD : FontWeights.BLACK"
      >
        <BaseCurrency>{{ item.priceData.marketPrice }}</BaseCurrency>
      </SharedText>
    </div>
    <div :class="['item-image__desktop', csItemImageDesktopClass]">
      <div class="item-image__picture-overlay" />
      <UiImage class="item-image__picture-desktop" :src="item.imageData.image" />
    </div>
    <div v-if="isTimer" class="item-timer__container">
      <SharedTimer
        :key="countTimer"
        size="smallest"
        description-date="colon"
        format="time"
        :date="timerDate"
        :text-props="{ size: isMobile ? '14px' : '20px' }"
        @on-expire="handleExpire"
      />

      <SharedButtonNew
        v-if="showTakeItemInfo"
        :state="{ disabled: true }"
        :form="takeItemButtonFormPreset"
        :color="itemBigGetButtonColorPreset"
        :text-props="{ size: FontSizes.SMALL_XS, color: Colors.NEUTRAL.WHITE, weight: FontWeights.BOLD }"
      >
        <span class="item__btn-take">{{ t(`${item.takeItemState}`).toLocaleUpperCase() }}</span>
      </SharedButtonNew>
      <SharedButtonNew
        v-if="item.status === statuses.completed"
        :form="itemBigGetButtonFormPreset"
        :color="itemBigGetButtonColorPreset"
        :text-props="{ size: FontSizes.SMALL_XS, color: Colors.NEUTRAL.WHITE, weight: FontWeights.BOLD }"
        @click="getItem"
      >
        <span class="item__btn-take">{{ t('get').toLocaleUpperCase() }}</span>
      </SharedButtonNew>
    </div>

    <div v-if="isBkCs" class="item-info__cs">
      <SharedText
        class="item-info__cs-name"
        :color="Colors.NEUTRAL.WHITE"
        :size="isMobile ? FontSizes.SMALL_XS : FontSizes.MEDIUM"
        display="block"
        :weight="FontWeights.MEDIUM"
        text-overflow="ellipsis"
        >{{ item.weapon }}
      </SharedText>
      <SharedText
        class="item-info__cs-quality"
        :color="Colors.NEUTRAL.WHITE"
        :size="isMobile ? FontSizes.SMALL_XS : FontSizes.MEDIUM"
        display="block"
        :weight="FontWeights.BOLD"
        text-overflow="ellipsis"
      >
        <span v-if="item.qualityShort">({{ item.qualityShort }})&nbsp;</span>
        {{ item.skin }}
      </SharedText>
    </div>
    <SharedText
      v-else
      class="item-info__name-desktop"
      :color="Colors.NEUTRAL.WHITE"
      :size="isMobile ? FontSizes.SMALL_XS : FontSizes.MEDIUM"
      :text-shadow="'0px 1px 1px rgba(0, 0, 0, 0.25)'"
      display="block"
      :weight="isMobile ? FontWeights.MEDIUM : FontWeights.BOLD"
      text-overflow="ellipsis"
      >{{ item.name }}
    </SharedText>
    <div v-if="item.type === '3'" class="item-info__steam-icon">
      <LazySvgoDarkSteamIcon />
    </div>
    <div v-if="!props.item.isOld" class="item-info__action-buttons-desktop">
      <SharedButtonNew
        v-if="showSellItemBtn"
        class="button__hovered"
        :form="itemBigSellButtonFormPreset"
        :color="itemBigSellButtonColorPreset"
        :text-props="iconStyles(false)"
        @click="sellItem"
      >
        {{ sellButtonContent }}
      </SharedButtonNew>
      <SharedButtonNew
        v-if="showTakeItemBtn"
        class="button__hovered"
        :form="itemBigGetButtonFormPreset"
        :text-props="iconStyles(true)"
        :color="itemBigGetButtonColorPreset"
        @click="sendItem"
      >
        <LazySvgoArrowDownBkIcon v-if="!isHovered" />
        {{ takeButtonContent }}
      </SharedButtonNew>
    </div>
    <div v-if="showIconSoldOrAccepted" class="item-info__badge" :style="badgeColor" />
    <component :is="badgeContent" v-if="showIconSoldOrAccepted" class="item-info__badge-content" />
  </div>
</template>
<script setup lang="ts">
import { useBkInventoryStore } from '~/features/bk/store/bk.inventory';
import {
  itemBigGetButtonColorPreset,
  itemBigGetButtonFormPreset,
  itemBigSellButtonColorPreset,
  itemBigSellButtonFormPreset,
  itemBigSellButtonHoverTextPreset,
  itemBigSellButtonTextPreset,
  itemGetButtonColorPreset,
  itemGetButtonTextPreset,
  itemSellButtonColorPreset,
  itemSellButtonTextPreset,
  itemSmallButtonFormPreset,
  takeItemStateTimers,
} from '~/features/bk/components/BKIntegrationItemComponent/BKIntegrationItemComponent.data';
import type {
  IBKIntegrationItemComponentEmits,
  IBKIntegrationItemComponentProps,
} from '~/features/bk/components/BKIntegrationItemComponent/BKIntegrationItemComponent.types';
import { FontSizes, FontWeights } from '~/types/SharedFont.types';
import { Breakpoints } from '~/constants/media.constants';
import { statuses, type TStatuses } from '~/types/cases/status';
import { Colors } from '~/constants/colors.constants';
import { EInventoryTakeStates } from '~/repository/modules/bk/bk.types';
import { defaultMiddleButtonFormPreset } from '~/components/SharedButtonNew/SharedButtonNew.data';

const bkInventoryStore = useBkInventoryStore();
const { setTakeItemState } = bkInventoryStore;
const { pendingSellItem, isPendingSellAll, itemIdsToReplaceInQueue } = storeToRefs(bkInventoryStore);

const emits = defineEmits<IBKIntegrationItemComponentEmits>();

const props = withDefaults(defineProps<IBKIntegrationItemComponentProps>(), {
  getButtonColorPreset: () => itemGetButtonColorPreset,
  getButtonTextPreset: () => itemGetButtonTextPreset,
  priceLineColor: 'var(--bk-integration-purple)',
  sellButtonColorPreset: () => itemSellButtonColorPreset,
  sellButtonTextPreset: () => itemSellButtonTextPreset,
});

const { item } = toRefs(props);

const VAR_DOTA_BG = '--dota-bg';
const VAR_DOTA_OVERLAY_BG = '--dota-overlay-bg';
const VAR_CS_BG_DESKTOP = '--cs-bg-desktop';
const VAR_CS_OVERLAY_BG = '--cs-overlay-bg';
const VAR_CS_BG_MOBILE = '--cs-bg-mobile';
const VAR_CS_ST_RIGHT = '--cs-st-right';

const SOLD_STATUSES: Array<keyof typeof statuses> = [statuses.selled, statuses.autoselled];

const csItemImageClass = computed<{ [key: string]: boolean }>(() => {
  return { 'item-image__cs': isBkCs.value };
});

const csDesktopContainerClass = computed<{ [key: string]: boolean }>(() => {
  return { 'item-container__desktop-cs': isBkCs.value };
});

const csItemImageDesktopClass = computed<{ [key: string]: boolean }>(() => {
  return { 'item-image__desktop-cs': isBkCs.value };
});

const takeItemButtonFormPreset = {
  ...defaultMiddleButtonFormPreset,
  height: '23px',
  minWidth: '100%',
  radius: 'var(--border-radius-4xs)',
  width: '100%',
};

const isNotActiveStatus: TStatuses[] = ['send', 'accepted', 'canceled', 'error', 'completed'];
const viewport = useViewport();
const isHovered = ref<boolean>(false);
const countTimer = ref<number>(0);
const isActive = computed<boolean>(() => !isNotActiveStatus.includes(props.item.status || 'progress'));
const isMobile = computed(() => !viewport.isGreaterThan(Breakpoints.md));
const isTimer = computed(() => typeof item.value.timerData?.timeToExpire === 'number');
const { t } = useI18n();

const sellingThisItem = computed(() => pendingSellItem.value === props.item.id);
const isTakeItemInQueue = computed(() => itemIdsToReplaceInQueue.value[+props.item.id]);

// кнопка продать
const showSellItemBtn = computed<boolean>(() => {
  return isActive.value && !sellingThisItem.value && !isPendingSellAll.value && !isTakeItemInQueue.value;
});
// кнопка забрать
const showTakeItemBtn = computed<boolean>(
  () => isActive.value && !isTakeItemInQueue.value && !sellingThisItem.value && !isPendingSellAll.value,
);

const showTakeItemInfoStatus: EInventoryTakeStates[] = [
  EInventoryTakeStates.PREPARING_ITEM,
  EInventoryTakeStates.AWAITING_SELLER,
  EInventoryTakeStates.WE_CHARGE_TO_THE_BALANCE,
];
// выкл кнопки с указанием статуса

const showTakeItemInfo = computed<boolean>(
  () =>
    !!item.value.takeItemState &&
    item.value.status !== statuses.completed &&
    showTakeItemInfoStatus.includes(item.value.takeItemState),
);

const getCsStStyle = computed(() => {
  if (!showIconSoldOrAccepted.value) return { right: '0px' };
  const right = viewport.isGreaterThan(Breakpoints.sm) ? '30px' : '20px';
  return { right };
});

const styles = computed(() => {
  const colors = GlobalUtils.Colors.getColorsRarity(props.item.qualityEnum?.name);
  return {
    '--line-color': props.priceLineColor,
    '--rarity-color': Colors.RARITY[props.item.qualityEnum?.name?.toUpperCase() as keyof typeof Colors.RARITY],
    [VAR_CS_BG_DESKTOP]:
      colors !== Colors.NEUTRAL.WHITE ? colors : 'linear-gradient(180deg, #0E1030 0%, var(--rarity-color) 100%)',
    [VAR_CS_BG_MOBILE]: `linear-gradient(180deg, #0E1030 0%, var(--rarity-color) 100%)`,
    [VAR_CS_OVERLAY_BG]: 'linear-gradient(180deg, rgb(24 27 68 / 0%) 35%, var(--rarity-color) 100%)',
    [VAR_CS_ST_RIGHT]: getCsStStyle.value.right,
    [VAR_DOTA_BG]: 'linear-gradient(0deg, var(--rarity-color) 0%, rgb(255 255 255 / 0.02%) 120%)',
    [VAR_DOTA_OVERLAY_BG]: 'linear-gradient(0deg, var(--rarity-color) 0%, rgb(255 255 255 / 0.02%) 35%)',
  };
});

const actualCurrency = computed(() => {
  return props.customCurrency ? props.customCurrency : props.item.offerData.currency;
});

const sellButtonContent = computed(() =>
  !isHovered.value
    ? '$'
    : `${t('sellFor').toUpperCase()} ${useFormatCurrency(props.item.priceData?.price, actualCurrency.value, true)}`,
);
const takeButtonContent = computed(() => (!isHovered.value ? '' : t('take').toUpperCase()));
const itemInfoStClass = computed(() => (showIconSoldOrAccepted.value ? 'item-info__cs-st--m' : ''));

const timerDate = computed<number>(() => item.value.timerData?.timeToExpire || 0);

const isSoldOrAutoSold = computed(() => SOLD_STATUSES.includes(props.item.status!));

const showIconSoldOrAccepted = computed(
  () => props.item.isOld && (props.isDemo || props.item.status === statuses.accepted || isSoldOrAutoSold.value),
);

const badgeColor = computed(() => {
  return {
    '--badge-color':
      !isSoldOrAutoSold.value && !props.isDemo ? 'var(--bk-integration-purple)' : 'var(--bk-integration-green)',
  };
});

const badgeContent = computed(() =>
  isSoldOrAutoSold.value || props.isDemo ? 'SvgoBkIntegrationDollarIcon' : 'SvgoBkIntegrationArrowDownIcon',
);
const iconStyles = computed(() => {
  return (isArrow = false) => {
    if (isArrow)
      return isHovered.value
        ? { ...itemBigSellButtonHoverTextPreset, color: 'var(--neutral-white)' }
        : { ...itemBigSellButtonTextPreset, color: 'var(--neutral-white)' };
    return isHovered.value ? itemBigSellButtonHoverTextPreset : itemBigSellButtonTextPreset;
  };
});

const statusBtnStyles = computed(() => {
  return { width: '100%' };
});

// Забрать найденный та ТП(торговой площадке) предмет
const getItem = () => {
  emits('on-get', +props.item.id);
};

// Отправить запрос на поиск предмета на торговой площадке
const sendItem = () => {
  emits('on-send', props.item);
};

// Продать предмет
const sellItem = () => {
  emits('on-sell', props.item);
};

// Определение типа проекта при интеграции с БК

const isBkCs = computed(() => {
  return !!props.item.weapon;
});

// Тип для configMap

type ConfigMap = {
  [key: string]: {
    id: number;
    takeItemStateSet: EInventoryTakeStates;
    time: number;
  };
};

// Обработчик истечения времени таймера

const configMap: ConfigMap = {
  [EInventoryTakeStates.PREPARING_ITEM]: {
    id: +item.value.id,
    takeItemStateSet: EInventoryTakeStates.PREPARING_ITEM,
    time: takeItemStateTimers.prepareItemTimer,
  },
  [EInventoryTakeStates.AWAITING_SELLER]: {
    id: +item.value.id,
    takeItemStateSet: EInventoryTakeStates.AWAITING_SELLER,
    time: takeItemStateTimers.awaitSellerTimer,
  },
  [EInventoryTakeStates.AWAITING_TAKING]: {
    id: +item.value.id,
    takeItemStateSet: EInventoryTakeStates.AWAITING_TAKING,
    time: takeItemStateTimers.takeItemTimerLong,
  },
  [EInventoryTakeStates.WE_CHARGE_TO_THE_BALANCE]: {
    id: +item.value.id,
    takeItemStateSet: EInventoryTakeStates.WE_CHARGE_TO_THE_BALANCE,
    time: takeItemStateTimers.takeItemTimerLong,
  },
};

const handleExpire = () => {
  if (!item.value.takeItemState) return;
  const config = configMap[item.value.takeItemState];
  if (!config) return;
  /** Необходимо для перерисовки таймера **/
  if (countTimer.value) countTimer.value--;
  else countTimer.value++;
  setTakeItemState(config);
};
</script>
<style scoped lang="scss" src="./BKIntegrationItemComponent.scss"></style>
