<template>
  <div class="empty-inventory__container" :style="style">
    <div class="empty-inventory__content">
      <UiImage v-if="isMobile" src="/static/empty-inventory.png" class="empty-inventory__image" />
      <div class="empty-inventory__text">
        <p class="empty-inventory__title">{{ t('noActiveItems') }}</p>
        <SharedButtonNew
          :form="buttonFormPresetPicker"
          :color="buttonColorPreset"
          :text-props="buttonTextPresetPicker"
          @click="emits('on-back')"
        >
          {{ t('returnToCase') }}</SharedButtonNew
        >
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import {
  emptyInventoryMediumButtonFormPreset,
  emptyInventoryMediumButtonTextPreset,
  emptyInventorySmallButtonFormPreset,
  emptyInventorySmallButtonTextPreset,
} from '~/features/bk/components/BKIntegrationEmptyInventory/BKIntegrationEmptyInventory.data';
import type {
  IBKIntegrationEmptyInventoryEmits,
  IBKIntegrationEmptyInventoryProps,
} from '~/features/bk/components/BKIntegrationEmptyInventory/BKIntegrationEmptyInventory.types';
import { Breakpoints } from '~/constants/media.constants';

const { t } = useI18n();

const emits = defineEmits<IBKIntegrationEmptyInventoryEmits>();

const props = defineProps<IBKIntegrationEmptyInventoryProps>();

const viewport = useViewport();

const isMobile = computed(() => {
  return viewport.isLessThan(Breakpoints.md);
});

const buttonFormPresetPicker = computed(() => {
  return viewport.isGreaterThan(Breakpoints.md)
    ? emptyInventoryMediumButtonFormPreset
    : emptyInventorySmallButtonFormPreset;
});

const buttonTextPresetPicker = computed(() => {
  return viewport.isGreaterThan(Breakpoints.md)
    ? emptyInventoryMediumButtonTextPreset
    : emptyInventorySmallButtonTextPreset;
});

const style = computed(() => {
  return { '--bg-color': props.backgroundColor };
});
</script>
<style scoped lang="scss" src="./BKIntegrationEmptyInventory.scss"></style>
