<template>
  <div class="block">
    <UiImage class="block__image" src="/static/error.png" />
    <div class="block__content">
      <SharedText v-bind="calculateTitleStyle">{{ title }}</SharedText>
      <span class="block__line"></span>
      <SharedText v-bind="calculateDescriptionStyle">{{ description }}</SharedText>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IErrorPopupProps } from '~/features/bk/components/BKIntegrationErrorPopup/BKIntegrationErrorPopup';
import { useAdaptivePreset } from '~/features/bk/composables/useAdaptivePreset';
import { useBkErrorStore } from '~/features/bk/store/bk.error';

const { t } = useI18n();
const bkErrorStore = useBkErrorStore();
const { errorCode } = storeToRefs(bkErrorStore);
const props = withDefaults(defineProps<Partial<IErrorPopupProps>>(), {
  description: '',
  title: '',
});
const { calculateTitleStyle, calculateDescriptionStyle } = useAdaptivePreset();

const title = shallowRef(props.title || t('error').toUpperCase());
const description = shallowRef(props.description || t('unpredictedException'));

watch(
  errorCode,
  () => {
    if (errorCode.value) {
      const errorKey = `errors.bkModal.${errorCode.value}`;
      title.value = t(`${errorKey}.title`);
      description.value = t(`${errorKey}.description`);
    }
  },
  { immediate: true },
);
</script>

<style src="./BKIntegrationErrorPopup.scss" scoped lang="scss"></style>
